import React, { useState } from "react";
import { Stack, DefaultButton, Panel } from "@fluentui/react";
import LogItem from "./LogItem";

const LogList = ({ logs }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  return (
    <div>
      <DefaultButton
        onClick={togglePanel}
        text={isPanelOpen ? "Schowaj logi" : "Pokaż logi"}
      />
      <Panel
        isOpen={isPanelOpen}
        closeButtonAriaLabel="Zamknij"
        headerText="Logi"
        onDismiss={togglePanel}
      >
        <Stack tokens={{ childrenGap: 10 }}>
          <DefaultButton
            onClick={togglePanel}
            text={isPanelOpen ? "Schowaj logi" : "Pokaż logi"}
          />
          {logs.map((log) => (
            <LogItem key={log.id} log={log} />
          ))}
        </Stack>
      </Panel>
    </div>
  );
};

export default LogList;
