import axios from "axios";

class ApiService {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL || "http://localhost:8080",
      // Tutaj dodaj inne globalne ustawienia
    });

    // Możesz także dodać interceptory, jeśli są potrzebne
    this.axiosInstance.interceptors.response.use(
      this.handleSuccess,
      this.handleError
    );
  }

  handleSuccess(response) {
    return response;
  }

  handleError(error) {
    // Tutaj obsłuż błędy, na przykład logowanie błędów lub odświeżanie tokenów
    console.log(error?.response?.data?.msg || "error");
    if (
      error?.response?.data?.msg ===
      "Nie można anulować terminu później niż 6 godzin przed jego rozpoczęciem."
    ) {
      alert(error?.response?.data?.msg);
    }
    // return Promise.reject(error);
  }

  //   █████╗ ██████╗ ███╗   ███╗██╗███╗   ██╗
  //  ██╔══██╗██╔══██╗████╗ ████║██║████╗  ██║
  //  ███████║██║  ██║██╔████╔██║██║██╔██╗ ██║
  //  ██╔══██║██║  ██║██║╚██╔╝██║██║██║╚██╗██║
  //  ██║  ██║██████╔╝██║ ╚═╝ ██║██║██║ ╚████║
  //  ╚═╝  ╚═╝╚═════╝ ╚═╝     ╚═╝╚═╝╚═╝  ╚═══╝
  //

  addAppoinmentsList(appointmentData) {
    return this.axiosInstance.post("/appointments/add-list", appointmentData);
  }

  getLogsList() {
    return this.axiosInstance.get("/logs");
  }

  getAppointmentsList() {
    return this.axiosInstance.get("/appointments/get-list");
  }

  editAppointmentsList(date, appointmentData) {
    return this.axiosInstance.put(
      `/appointments/edit-list/${date}`,
      appointmentData
    );
  }

  deleteAppointmentsList(appointmentData) {
    console.log(appointmentData);
    return this.axiosInstance.delete(
      `/appointments/delete-list/${appointmentData.date}`,
      appointmentData
    );
  }

  //  ██╗   ██╗███████╗███████╗██████╗ ███████╗
  //  ██║   ██║██╔════╝██╔════╝██╔══██╗██╔════╝
  //  ██║   ██║███████╗█████╗  ██████╔╝███████╗
  //  ██║   ██║╚════██║██╔══╝  ██╔══██╗╚════██║
  //  ╚██████╔╝███████║███████╗██║  ██║███████║
  //   ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚══════╝
  //

  getFreeTerms() {
    return this.axiosInstance.get("/appointments");
  }

  bookAppointment(appointmentId) {
    const path = window.location.pathname;
    const login = path.split("/")[1];

    return this.axiosInstance.post("/appointments/book", {
      appointmentId,
      login,
    });
  }

  cancelAppointment(appointmentId) {
    return this.axiosInstance.put(`/appointments/cancel/${appointmentId}`);
  }

  cancelAppointment(appointmentId) {
    return this.axiosInstance.put(`/appointments/cancel/${appointmentId}`);
  }

  getUserData(username) {
    console.log("username", username);
    return this.axiosInstance.get(`/user/${username}`);
  }

  // Dodaj inne metody związane z API
}

export default new ApiService();
