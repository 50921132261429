import React, { useState, useEffect } from "react";
import apiService from "./axiosInstance"; // Twój serwis API
import "./App.css";
import { DefaultButton, DocumentCard } from "@fluentui/react";
import FreeTerms from "./components/FreeTerms";
import moment from "moment";
import Admin from "./modules/admin/admin";

function App() {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async (username) => {
      try {
        const response = await apiService.getUserData(username);
        setUserData(response.data);
      } catch (error) {
        console.error("Błąd podczas pobierania danych użytkownika:", error);
      }
    };

    // Wyciąganie nazwy użytkownika z URL
    const path = window.location.pathname;
    const username = path.split("/")[1]; // Zakładając, że URL ma format /nazwa_użytkownika
    if (username) fetchUserData(username);
  }, []);

  const isDateTimeAfter = (date, time) => {
    const userDate = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm");
    const now = moment();

    return userDate.isBefore(now);
  };

  if (!userData)
    return (
      <div className="App">
        <DocumentCard className="terms-container">
          <p>Ładowanie danych użytkownika...</p>
        </DocumentCard>
      </div>
    );

  if (userData.type === 9) return <Admin />;

  if (userData.term && userData.term.id) {
    return (
      <div className="App">
        <DocumentCard className="terms-container">
          <h4>Witaj, {userData.login}!</h4>

          <h4>Wybrany przez Ciebie termin, to:</h4>
          <h4>
            {userData.term.date} {userData.term.start_time}
          </h4>

          {console.log(userData.term)}

          {isDateTimeAfter(userData.term.date, userData.term.start_time) ? (
            <p>
              Wykorzystałeś spotkanie w tym tygodniu. Kolejna terminy pojawią
              się w najbliższy wtorek.
            </p>
          ) : (
            <DefaultButton
              text="Odwołaj termin"
              onClick={async () => {
                await apiService.cancelAppointment(userData.term.id);
                window.location.reload();
              }}
            />
          )}
        </DocumentCard>
      </div>
    );
  }

  return (
    <div className="App">
      <DocumentCard className="terms-container">
        <h4>Witaj, {userData.login}!</h4>

        <h4>Wybierz termin spotkania.</h4>
        <p>Aplikacja działa w czasie polskim.</p>
        <p>Terminy udostępniane są zwykle na 2 najbliższe dni.</p>
        <FreeTerms />
      </DocumentCard>
    </div>
  );
}

export default App;
