import React, { useState } from "react";
import {
  Modal,
  DefaultButton,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import moment from "moment";
import axiosInstance from "../axiosInstance";

const EditAppointmentComponent = ({ appointmentData }) => {
  console.log({ appointmentData });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedHours, setEditedHours] = useState({
    added: [],
    removed: [],
  });

  const initializeSelectedHours = (hours) => {
    return hours.reduce((acc, hour) => {
      acc[hour.start_time] = true;
      return acc;
    }, {});
  };

  const [selectedHours, setSelectedHours] = useState(
    initializeSelectedHours(appointmentData.hours)
  );

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const toggleHourSelection = (time) => {
    const isDisabled = appointmentData.hours.some(
      (hour) => hour.start_time === time && hour.login
    );
    if (isDisabled) return;
    setSelectedHours((prev) => {
      const currentlySelected = { ...prev };
      if (currentlySelected[time]) {
        setEditedHours((prev) => ({
          ...prev,
          removed: [...prev.removed, time],
          added: prev.added.filter((t) => t !== time),
        }));
      } else {
        setEditedHours((prev) => ({
          ...prev,
          added: [...prev.added, time],
          removed: prev.removed.filter((t) => t !== time),
        }));
      }
      currentlySelected[time] = !currentlySelected[time];
      return currentlySelected;
    });
  };

  const handleConfirm = async () => {
    console.log("Added times:", editedHours.added);
    console.log("Removed times:", editedHours.removed);
    // Logika wysyłania zmian do backendu
    await axiosInstance.editAppointmentsList(appointmentData.date, {
      ...editedHours,
    });
    window.location.reload();
  };

  const generateTimeButtons = () => {
    let times = [];
    const startTime = moment("7:00", "HH:mm");
    const endTime = moment("22:00", "HH:mm");
    const timeDiff = moment.duration(45, "minutes");

    while (startTime.isSameOrBefore(endTime)) {
      const hourFormatted = startTime.format("HH:mm");
      const isSelected = selectedHours[hourFormatted];
      const isDisabled = appointmentData.hours.some(
        (hour) => hour.start_time === hourFormatted && hour.login
      );

      times.push(
        <DefaultButton
          key={hourFormatted}
          text={hourFormatted}
          onClick={() => !isDisabled && toggleHourSelection(hourFormatted)}
          style={{
            margin: "5px",
            backgroundColor: isSelected ? "#ffe599" : "", // Żółty jeśli zaznaczony
          }}
          disabled={isDisabled} // Wyłącza przycisk jeśli termin ma przypisany login
        />
      );

      startTime.add(timeDiff);
    }

    return times;
  };

  return (
    <div>
      <DefaultButton text="Edytuj terminy" onClick={openModal} />
      <Modal
        isOpen={isModalOpen}
        onDismiss={closeModal}
        isBlocking={false}
        containerClassName="container"
      >
        <Stack tokens={{ childrenGap: 10, padding: 10 }}>
          <Text variant="large">
            {moment(appointmentData.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
          </Text>
          <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
            {generateTimeButtons()}
          </div>
          <PrimaryButton
            text="Edytuj"
            onClick={handleConfirm}
            style={{ marginTop: "20px" }}
          />
          <DefaultButton
            text="Anuluj"
            onClick={closeModal}
            style={{ marginTop: "20px" }}
          />
        </Stack>
      </Modal>
    </div>
  );
};

export default EditAppointmentComponent;
