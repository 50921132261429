export const dayPickerStrings = {
  months: [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień",
  ],

  shortMonths: [
    "Sty",
    "Lut",
    "Mar",
    "Kwi",
    "Maj",
    "Cze",
    "Lip",
    "Sie",
    "Wrz",
    "Paź",
    "Lis",
    "Gru",
  ],

  days: [
    "Niedziela",
    "Poniedziałek",
    "Wtorek",
    "Środa",
    "Czwartek",
    "Piątek",
    "Sobota",
  ],

  shortDays: ["N", "P", "W", "Ś", "C", "P", "S"],

  goToToday: "Idź do dzisiaj",
  prevMonthAriaLabel: "Idź do poprzedniego miesiąca",
  nextMonthAriaLabel: "Idź do następnego miesiąca",
  prevYearAriaLabel: "Idź do poprzedniego roku",
  nextYearAriaLabel: "Idź do następnego roku",

  // Tłumaczenie dodatkowych opcji
  closeButtonAriaLabel: "Zamknij",

  // Możesz dodać więcej tłumaczeń dla innych ciągów, które są używane w DatePicker
};
