import React, { useState, useEffect } from "react";
import {
  DocumentCard,
  Stack,
  Text,
  IconButton,
  DefaultButton,
} from "@fluentui/react";
import moment from "moment";

import axiosInstance from "../../axiosInstance";
import AddAppointmentComponent from "../../components/AddAppointment";
import EditAppointmentComponent from "../../components/EditAppointment";
import { getDayOfWeek } from "../../utils/dates";
import LogList from "../../components/LogList";

const groupAppointmentsByDate = (appointments) => {
  const grouped = appointments.reduce(
    (acc, { id, start_time, date, login, type }) => {
      if (!acc[date]) acc[date] = [];
      acc[date].push({ id, start_time, login, type });
      return acc;
    },
    {}
  );

  return Object.entries(grouped).map(([date, hours]) => ({ date, hours }));
};

const Admin = () => {
  const [terms, setTerms] = useState([]);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await axiosInstance.getAppointmentsList();
        response.data.sort((a, b) => {
          const dateA = new Date(a.date + " " + a.start_time);
          const dateB = new Date(b.date + " " + b.start_time);
          return dateA - dateB;
        });

        const mappedTerms = groupAppointmentsByDate(response.data);
        setTerms(mappedTerms);
      } catch (error) {
        console.error("Błąd podczas pobierania terminów:", error);
      }
    };

    fetchTerms();
  }, []);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await axiosInstance.getLogsList();
        setLogs(response.data);
      } catch (error) {
        console.error("Błąd podczas pobierania logów:", error);
      }
    };

    fetchLogs();
  }, []);

  const handleEdit = (id) => {
    // Logika edycji terminu
  };

  const handleDelete = async (date) => {
    // Logika usuwania terminów z danego dnia
    await axiosInstance.deleteAppointmentsList(date);
    window.location.reload();
  };

  const colorBackgrounds = {
    1: "front",
    2: "python",
  };

  return (
    <div className="App">
      <DocumentCard className="terms-container">
        {terms.map((day) => (
          <Stack key={day.date} tokens={{ childrenGap: 10, padding: 10 }}>
            <Text variant="large">
              {moment(day.date).format("YYYY-MM-DD")} - {getDayOfWeek(day.date)}
            </Text>
            <Stack tokens={{ childrenGap: 10 }}>
              {day.hours.map((term) => (
                <DefaultButton
                  className={colorBackgrounds[term.type]}
                  key={term.id}
                  text={`${term.start_time} ${term.login ? term.login : ""}`}
                />
              ))}
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <EditAppointmentComponent appointmentData={day} />

              <DefaultButton
                text="Usuń wolne"
                onClick={() => handleDelete(day)}
              />
            </Stack>
          </Stack>
        ))}
        <AddAppointmentComponent />

        <LogList logs={logs} />
      </DocumentCard>
    </div>
  );
};

export default Admin;
