import React, { useState } from "react";
import {
  Modal,
  DefaultButton,
  PrimaryButton,
  DatePicker,
  DayOfWeek,
} from "@fluentui/react";
import axiosInstance from "../axiosInstance";
import moment from "moment";
import { dayPickerStrings } from "../config/datePickerTranslate";

const AddAppointmentComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("DD-MM-YYYY")
  );
  const [selectedTimes, setSelectedTimes] = useState([]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setSelectedTimes([]);
    setIsModalOpen(false);
  };

  const onDateChange = (date) => {
    setSelectedDate(moment(date).format("DD-MM-YYYY"));
  };

  const onTimeSelect = (time) => {
    if (selectedTimes.includes(time)) {
      setSelectedTimes(selectedTimes.filter((t) => t !== time));
    } else {
      setSelectedTimes([...selectedTimes, time]);
    }
  };

  const handleConfirm = async () => {
    await axiosInstance.addAppoinmentsList({
      date: moment(selectedDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      times: selectedTimes,
    });
    closeModal();
    window.location.reload();
  };

  const generateTimeButtons = () => {
    let times = [];
    let startTime = moment("07:00", "HH:mm");
    let endTime = moment("22:00", "HH:mm");

    while (startTime.isSameOrBefore(endTime)) {
      let time = startTime.format("HH:mm");
      times.push(
        <DefaultButton
          key={time}
          text={time}
          onClick={() => onTimeSelect(time)}
          style={{
            margin: "5px",
            backgroundColor: selectedTimes.includes(time) ? "#ffe599" : "",
          }}
        />
      );
      startTime.add(45, "minutes");
    }

    return times;
  };

  return (
    <div>
      <DefaultButton text="Dodaj termin" onClick={openModal} />
      <Modal
        isOpen={isModalOpen}
        onDismiss={closeModal}
        isBlocking={false}
        containerClassName="container"
      >
        <DatePicker
          value={moment(selectedDate, "DD-MM-YYYY").toDate()}
          onSelectDate={onDateChange}
          firstDayOfWeek={DayOfWeek.Monday}
          placeholder="Wybierz datę"
          strings={dayPickerStrings}
        />
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
          {generateTimeButtons()}
        </div>
        <PrimaryButton
          text="Zatwierdź"
          onClick={handleConfirm}
          style={{ marginTop: "20px" }}
        />
        <DefaultButton
          text="Anuluj"
          onClick={closeModal}
          style={{ marginTop: "20px" }}
        />
      </Modal>
    </div>
  );
};

export default AddAppointmentComponent;
