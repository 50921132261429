export const getDayOfWeek = (dateString) => {
  const days = [
    "Niedziela",
    "Poniedziałek",
    "Wtorek",
    "Środa",
    "Czwartek",
    "Piątek",
    "Sobota",
  ];
  const date = new Date(dateString);
  return days[date.getDay()];
};
