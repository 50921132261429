import React, { useState, useEffect } from "react";
import { Stack, DefaultButton } from "@fluentui/react";
import axiosInstance from "../axiosInstance";
import { getDayOfWeek } from "../utils/dates";

const FreeTerms = () => {
  const [terms, setTerms] = useState(null);

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await axiosInstance.getFreeTerms();
        setTerms(response.data);
      } catch (error) {
        console.error("Błąd podczas pobierania terminów:", error);
      }
    };

    fetchTerms();
  }, []);

  const reserveTerm = async (termId) => {
    try {
      await axiosInstance.bookAppointment(termId);
      window.location.reload();
    } catch (error) {
      console.error("Błąd podczas rezerwacji terminu:", error);
    }
  };

  const renderTerms = () => {
    if (terms.length === 0) return <p>Brak wolnych terminów</p>;

    terms.sort((a, b) => {
      const dateA = new Date(a.date + " " + a.start_time);
      const dateB = new Date(b.date + " " + b.start_time);
      return dateA - dateB;
    });

    // Grupowanie terminów według daty
    const groupedTerms = terms.reduce((acc, term) => {
      acc[term.date] = acc[term.date] || [];
      acc[term.date].push(term);
      return acc;
    }, {});

    return Object.entries(groupedTerms).map(([date, terms]) => (
      <Stack key={date} tokens={{ childrenGap: 10 }}>
        <h3>
          {date} - {getDayOfWeek(date)}
        </h3>
        <div className="free-terms" horizontal tokens={{ childrenGap: 10 }}>
          {terms.map((term) => (
            <DefaultButton key={term.id} onClick={() => reserveTerm(term.id)}>
              {term.start_time.substring(0, 5)}
              {term.login ? `-${term.login}` : ""}
            </DefaultButton>
          ))}
        </div>
      </Stack>
    ));
  };

  return (
    <Stack tokens={{ padding: 20 }}>
      {terms ? renderTerms() : <p>Ładowanie terminów...</p>}
    </Stack>
  );
};

export default FreeTerms;
