import React from "react";
import { Label, Stack } from "@fluentui/react";
import moment from "moment";

const LogItem = ({ log }) => {
  // Formatowanie daty i czasu w twojej strefie czasowej
  const formattedTimestamp = moment(log.timestamp).format("YYYY-MM-DD HH:mm");

  return (
    <>
      <Label>{formattedTimestamp}</Label>
      {log.message}
    </>
  );
};

export default LogItem;
